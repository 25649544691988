export const useIsCurrentRoute = (
  path: string,
  opts?: { matchExact?: boolean; noMatchPaths?: Array<string> },
) => {
  const currentRoute = useRoute();

  const matchesPath =
    opts && opts.matchExact
      ? currentRoute.path === path
      : currentRoute.path.includes(path);

  const matchesNoMatchPaths =
    opts && opts.noMatchPaths
      ? opts.noMatchPaths.some((noMatchPath) =>
          currentRoute.path.includes(noMatchPath),
        )
      : false;

  return matchesPath && !matchesNoMatchPaths;
};
